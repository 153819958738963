import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PageHome from './PageHome';
import PageDilaraDuman from './PageDilaraDuman';
//import PageNotFound from './PageNotFound';

export class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path='/' component={PageHome} />
            <Route path='/dilara-duman' component={PageDilaraDuman} />
            <Route component={PageHome} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App;