import React, { Component } from "react";
import Parser from 'html-react-parser';

export class Header extends Component {
  constructor(props) {
    super(props);

    const images = ["https://www.duman-law.com/img/bg-1.jpg", "https://www.duman-law.com/img/bg-2.jpg", "https://www.duman-law.com/img/bg-3.jpg"];

    this.state = {
      images,
      currentImg: 0
    }
  }
  componentDidMount() {
    this.interval = setInterval(() => this.changeBackgroundImage(), 5000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  changeBackgroundImage() {
    let newCurrentImg = 0;
    const {images, currentImg} = this.state;
    const noOfImages = images.length;

    if (currentImg !== noOfImages - 1) {
      newCurrentImg = currentImg + 1;
    }

    this.setState({currentImg: newCurrentImg});
  }

  render() {
    const {images, currentImg} = this.state;
    const urlString = `url('${images[currentImg]}')`;
    const IntroStyle = {
      backgroundImage: urlString,
      backgroundSize: "cover"
    }
    return (
      <header id="header">
        <div style={IntroStyle} className="intro">
          <div className="overlay">
            <div className="container container-top">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <div className="intro-content-box">
                    <h2>
                      {this.props.data ? this.props.data.title : "Loading"}
                      <span></span>
                    </h2>
                    <p>
                      {Parser(this.props.data ? this.props.data.content : 'loading...')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-separator" />
      </header>
    );
  }
}

export default Header;