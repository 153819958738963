import React, { Component } from "react";

export class Footer extends Component {
    render() {
        return (
            <div id="footer">
                <div className="container text-center">
                    <p>
                        &copy; 2021 Duman Law Office
                    </p>
                </div>
            </div>
        )
    }
}

export default Footer;