import React, { Component } from 'react'
import Cookies from 'universal-cookie';

import Navigation from './components/Navigation';
import Footer from './components/Footer';
import DilaraDuman from './components/DilaraDuman';

import GA4React from 'ga-4-react';

export class PageDilaraDuman extends Component {
  state = {
    loaded: false,
    landingPageData: {},
  }

  getData(lang) {
    return fetch('https://www.duman-law.com/api/wp-json/ozgunapi/v1/lang=' + lang)
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({landingPageData: responseJson})
      this.setState({loaded: true})
    })
    .catch((error) => {
      console.error(error);
    });
  }

  componentWillMount() {
    const cookies = new Cookies();
    switch(cookies.get('lang')) {
      case "tr":
        this.setState({landingPageData : this.getData('tr')})
        break;
      case "en":
        this.setState({landingPageData : this.getData('en')})
        break;
      default:
        this.setState({landingPageData : this.getData('en')})
    }
  }

  componentDidMount() {
    const ga4react = new GA4React('G-DK937JZ5JF');
    ga4react.initialize().then((ga4) => {
      ga4.pageview('path')
      ga4.gtag('event','pageview','path')
    },(err) => {
      console.error(err)
    })
  }

  render() {
    if ( this.state.loaded ) {
    return (
        <div>
            <Navigation data={this.state.landingPageData['navigation']} />
            <DilaraDuman data={this.state.landingPageData['dilara-duman']} />
            <Footer />
        </div>
      )
    } else {
      return null;
    }
  }
}

export default PageDilaraDuman;