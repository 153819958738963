import React, { Component } from 'react';
import Parser from 'html-react-parser';

export class AreasOfPractice extends Component {
  render() {
    return (
        <div id="areas-of-practice">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="areas-of-practice-text">
                <h2>{this.props.data ? this.props.data.title : 'loading...'}</h2>
                {Parser(this.props.data ? this.props.data.content : 'loading...')}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AreasOfPractice