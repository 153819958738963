import React, { Component } from "react";
import Parser from 'html-react-parser';

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="contact-columns col-md-3">
              <div className="row">
                <div className="section-title">
                  <h2>{this.props.data ? this.props.data.title : 'loading...'}</h2>
                  <p>
                    {this.props.data ? this.props.data.form_text : 'loading...'}
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder={this.props.data ? this.props.data.form_input_name : 'loading...'}
                          required="required"
                        />
                        <p className="help-block"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder={this.props.data ? this.props.data.form_input_email : 'loading...'}
                          required="required"
                        />
                        <p className="help-block"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder={this.props.data ? this.props.data.form_input_message : 'loading...'}
                      required
                    ></textarea>
                    <p className="help-block"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    {this.props.data ? this.props.data.form_send_button : 'loading...'}
                  </button>
                </form>
              </div>
            </div>
            <div className="contact-columns col-md-5 col-md-offset-1 contact-info">
              <div className="row">
                <iframe title="duman-maps" className="duman-maps"
                src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=tr&amp;geocode=&amp;q=Filistin+Caddesi+17%2F7+&amp;aq=&amp;sll=39.889875,32.855049&amp;sspn=0.003519,0.008256&amp;ie=UTF8&amp;hq=&amp;hnear=100.+Y%C4%B1l+Mh.,+Filistin+Sk+No:17,+Ankara%2F%C3%87ankaya,+T%C3%BCrkiye&amp;ll=39.897948,32.868622&amp;spn=0.007038,0.016512&amp;t=m&amp;z=15&amp;output=embed"
                height="500" width="100%" />
              </div>
            </div>
            <div className="contact-columns col-md-2 col-md-offset-1 contact-info">
              {Parser(this.props.data ? this.props.data.content : 'loading...')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;