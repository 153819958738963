import React, { Component } from "react";
import Cookies from 'universal-cookie';
import Logo from "../duman-logo.png";

const cookies = new Cookies();
export class Navigation extends Component {
  setUserLang() {
    switch(cookies.get('lang')) {
      case "tr":
        cookies.set('lang', 'en');
        break;
      case "en":
        cookies.set('lang', 'tr');
        break;
      default:
        cookies.set('lang', 'tr');
    }
  }
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="page-scroll" href={process.env.PUBLIC_URL + '/#page-top'}>
              <img src={Logo} className="duman-law-logo" alt=""/>
              <p className="slogan">Diligent and Talented Approach</p>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
            {this.props.data
              ? this.props.data.menu.map((d, i) => (
                  <li key={`${d.title}-${i}`}>
                    <a key={`${d.title}-${i}`} href={process.env.PUBLIC_URL + '/' + d.link} className="page-scroll">
                      {d.title}
                    </a>
                  </li>
                ))
              : "loading"}
              <li>
                <a href={process.env.PUBLIC_URL} onClick={this.setUserLang} className="page-scroll-lang-change">
                  {this.props.data ? this.props.data.langLang : 'loading...'}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;