import React, { Component } from 'react'
import Cookies from 'universal-cookie';

import Navigation from './components/Navigation';
import Header from './components/Header';
import About from './components/About';
import Publications from './components/Publications';
import AreasOfPractice from './components/AreasOfPractice';
import JoinUs from './components/Join';
import Contact from './components/Contact';
import Footer from './components/Footer';

import GA4React from 'ga-4-react';

export class PageHome extends Component {
  state = {
    loaded: false,
    landingPageData: {},
  }

  getData(lang) {
    return fetch('https://www.duman-law.com/api/wp-json/ozgunapi/v1/lang=' + lang)
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({landingPageData: responseJson})
      this.setState({loaded: true})
    })
    .catch((error) => {
      console.error(error);
    });
  }

  componentWillMount() {
    const cookies = new Cookies();
    switch(cookies.get('lang')) {
      case "tr":
        this.setState({landingPageData : this.getData('tr')})
        break;
      case "en":
        this.setState({landingPageData : this.getData('en')})
        break;
      default:
        this.setState({landingPageData : this.getData('en')})
    }
  }

  componentDidMount() {
    const ga4react = new GA4React('G-DK937JZ5JF');
    ga4react.initialize().then((ga4) => {
      ga4.pageview('path')
      ga4.gtag('event','pageview','path')
    },(err) => {
      console.error(err)
    })
  }

  render() {
    if ( this.state.loaded ) {
      return (
        <div>
            <Navigation data={this.state.landingPageData['navigation']} />
            <Header data={this.state.landingPageData['header']} />
            <About data={this.state.landingPageData['about']} />
            <AreasOfPractice data={this.state.landingPageData['areas-of-practice']} />
            <Publications data={this.state.landingPageData['publications']} />
            <JoinUs data={this.state.landingPageData['join']} />
            <Contact data={this.state.landingPageData['contact']} />
            <Footer />
        </div>
      )
    } else {
      return null;
    }
  }
}

export default PageHome;