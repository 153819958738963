import React, { Component } from 'react';
import Parser from 'html-react-parser';

export class DilaraDuman extends Component {
    render() {
        return (
            <div id="dilara-duman">
                <div className="container">
                    <div className="dilara-duman-text">
                        <h2>{this.props.data ? this.props.data.title : 'loading...'}</h2>
                        <div className="row">
                            <div className="col-xs-12 col-md-2">
                                <img src={this.props.data ? this.props.data.image : 'loading...'} className="dilara-duman-img img-responsive" alt=""/>
                            </div>
                            <div className="col-xs-12 col-md-10">
                                {Parser(this.props.data ? this.props.data.content : 'loading...')}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        )
    }
}

export default DilaraDuman